import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../components/Loading";
import { MyContext } from "../context/GlobalContextProvider";
import { Container, Divider, Typography, Stack, Card, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import customerService from "../services/CustomerService";
import customerLocationService from "../services/CustomerLocationService";
import DeliveryPartnerService from "../services/DeliveryPartnerService";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useSnackbar } from "notistack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import OrderDeliveryService from "../services/OrderDeliveryService";
import { UpdateOrderDelivery } from "../services/OrderDeliveryService";

export const OrderDetailPage = () => {
    const navigate = useNavigate();
    const { orderIdParam } = useParams();
    const { states } = useContext(MyContext);
    const { user, products, distributionCenter, orderDeliveryStatus } = states;

    const [loading, setLoading] = useState(true);
    const [listOrderProduct, setListOrderProduct] = useState(null);
    const [order, setOrder] = useState(null);
    const [orderDelivery, setOrderDelivery] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [deliveryPartner, setDeliveryPartner] = useState(null);
    const [customerLocation, setCustomerLocation] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const paymentMethods = {
        1: "Monedero",
        2: "Puntos",
        3: "Transferencia SINPE",
        4: "Efectivo",
        5: "Tarjeta Crédito/Débito",
        6: "Transferencia Bancaria",
    };

    function CopyButton({ textToCopy }) {
        const handleCopy = () => {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    // Muestra una notificación de éxito
                    enqueueSnackbar("Texto copiado al portapapeles", { variant: "success" });
                })
                .catch((err) => {
                    // Maneja el error
                    enqueueSnackbar("Error al copiar", { variant: "error" });
                });
        };

        return (
            <IconButton onClick={handleCopy} aria-label="copiar">
                <FileCopyIcon />
            </IconButton>
        );
    }

    const LoadOrderDetail = async () => {
        const responseOrderProduct = await axios.get(`orderproduct/${orderIdParam}`, { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } });
        if (responseOrderProduct !== null && responseOrderProduct.status === 200) {
            setListOrderProduct(responseOrderProduct.data);
        }

        const responseOrder = await axios.get(`order/${orderIdParam}`, { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } });
        if (responseOrder !== null && responseOrder.status === 200) {
            setOrder(responseOrder.data);
        }

        const responseCustomer = await customerService.getById(responseOrder.data.customerId);
        if (responseCustomer !== null && responseCustomer.status === 200) {
            setCustomer(responseCustomer.data.Result);
        }

        const responseOrderDelivery = await OrderDeliveryService.getByOrderId(orderIdParam);
        if (responseOrderDelivery !== null && responseOrderDelivery.status === 200) {
            setOrderDelivery(responseOrderDelivery.data);
        }

        const responseDeliveryPartner = await DeliveryPartnerService.getById(responseOrderDelivery.data.deliveryPartnerId);
        if (responseDeliveryPartner !== null && responseDeliveryPartner.status === 200) {
            setDeliveryPartner(responseDeliveryPartner.data.Result);
        }

        const responseCustomerLocation = await customerLocationService.getById(responseOrderDelivery.data.customerLocationId);
        if (responseCustomerLocation !== null && responseCustomerLocation.status === 200) {
            setCustomerLocation(responseCustomerLocation.data.Result);
        }
    };

    async function HandleOnClick() {
        setLoading(true);
        order.orderStatusId = 6;
        try {
            await fetch(`order/`, { method: "PUT", headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY }, body: JSON.stringify(order) }).then((response) => {
                if (response.status === 200) {
                    navigate("/pedidos");
                }
            });
        } catch (Error) {
            /* console.log(Error); */
        } finally {
            setLoading(false);
        }
    }

    async function handleDistributionCenterChange(newDistributionCenterId) {
        const updatedOrder = {
            ...orderDelivery,
            distributionCenterId: newDistributionCenterId
        };

        setOrderDelivery(updatedOrder);

        try {
            const response = await UpdateOrderDelivery(updatedOrder);

            if (response !== undefined) {
                enqueueSnackbar("Pedido actualizado con exito", { variant: "success" });
            }
        } catch (error) {
            enqueueSnackbar("Error al actualizar el pedido", { variant: "error" });
        }
    };


    useEffect(() => {
        if (listOrderProduct != null) {
            setLoading(false);
        }
    }, [listOrderProduct]);

    useEffect(() => {
        LoadOrderDetail();
    }, []);

    return (
        <Container maxWidth="xl" align="center">
            <Typography component="h2" variant="h4" m={1}>
                Detalle de Pedido
            </Typography>

            {orderDelivery === null ? (
                <></>
            ) : (
                <>
                    <Divider />
                    {order.orderDeliveryStatusId != 1 ?

                        <h3 className="mt-3 mb-3">{distributionCenter ? distributionCenter.find((x) => x.id === orderDelivery.distributionCenterId)?.name || "No encontrado" : "cargando"}</h3>

                        :

                        <FormControl sx={{ minWidth: '100%' }} className="mt-3 mb-3">
                            {/*<InputLabel id="distribution-center-label">Centro de Distribución</InputLabel>*/}
                            <Select
                                labelId="distribution-center-label"
                                id="distribution-center"
                                value={orderDelivery.distributionCenterId}
                                onChange={(event) => handleDistributionCenterChange(event.target.value)}
                                sx={{
                                    '& .MuiSelect-select': {
                                        fontSize: '1.25rem',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }
                                }}
                            >
                                {distributionCenter
                                    ? distributionCenter.map((center) => (
                                        <MenuItem key={center.id} value={center.id}>
                                            {center.name}
                                        </MenuItem>
                                    ))
                                    : <MenuItem value=""><em>Cargando</em></MenuItem>
                                }
                            </Select>
                        </FormControl>
                    }
                </>
            )}

            {order === null ? (
                <></>
            ) : (
                <>
                    <Divider className="mb-3" />
                    <h4 className="pt-1 pb-1" align="center">
                        Estado: {orderDeliveryStatus ? orderDeliveryStatus.find((status) => status.id === order.orderDeliveryStatusId)?.name || "Cancelado" : "cargando"}
                    </h4>

                    <h4 className="pt-1 pb-1" align="center">
                        Método de pago: {paymentMethods[order.paymentTypeId] || "Desconocido"}
                    </h4>
                    <Divider className="mb-3" />
                </>
            )}

            <Stack direction={{ sm: "column", md: "row" }} spacing={3} justifyContent="space-between">
                {customer === null ? (
                    <p>cargando..</p>
                ) : (
                    <Card className="w-100">
                        <h4 className="pt-1 pb-1">Cliente</h4>
                        <p>
                            Nombre: {customer.firstName} {customer.lastName}
                        </p>
                        {/* <p>Identificación: {customer.identification}</p> */}
                        <>
                            <p>
                                Celular: {customer.phoneNumber}{" "}
                                <a href={`https://wa.me/506${customer.phoneNumber}`} target="_blank" rel="noopener noreferrer">
                                    <WhatsAppIcon />
                                </a>
                            </p>
                        </>
                    </Card>
                )}

                {customerLocation === null ? (
                    <></>
                ) : (
                    <Card className="w-100">
                        <h4 className="pt-1 pb-1" align="center">
                            Punto de entrega
                        </h4>

                        <div>
                            <a className="me-1" href={`https://www.google.com/maps?q=${customerLocation.latitude},${customerLocation.longitude}`} target="_blank" rel="noopener noreferrer">
                                Abrir en Google Maps
                            </a>
                            <CopyButton textToCopy={`https://www.google.com/maps?q=${customerLocation.latitude},${customerLocation.longitude}`} />
                        </div>

                        <div>
                            <a className="me-1" href={`https://waze.com/ul?ll=${customerLocation.latitude},${customerLocation.longitude}&navigate=yes`} target="_blank" rel="noopener noreferrer">
                                Abrir en Waze
                            </a>
                            <CopyButton textToCopy={`https://waze.com/ul?ll=${customerLocation.latitude},${customerLocation.longitude}&navigate=yes`} />
                        </div>
                    </Card>
                )}

                {deliveryPartner === null ? (
                    <></>
                ) : (
                    <Card className="w-100">
                        <h4 className="pt-1 pb-1" align="center">
                            Repartidor
                        </h4>
                        <p>
                            Nombre: {deliveryPartner.firstName} {deliveryPartner.lastName}
                        </p>
                        {/* <p>Identificación: {deliveryPartner.identification}</p> */}
                        <p>
                            Celular: {deliveryPartner.phoneNumber}{" "}
                            <a href={`https://wa.me/506${deliveryPartner.phoneNumber}`} target="_blank" rel="noopener noreferrer">
                                <WhatsAppIcon />
                            </a>
                        </p>
                    </Card>
                )}
            </Stack>

            {listOrderProduct === null ? (
                loading ? (
                    <Loading />
                ) : (
                    <p>No se pudo cargar el pedido</p>
                )
            ) : (
                <>
                    <Typography component="h4" variant="h4" mt={1}>
                        Productos
                    </Typography>
                    <div className="table-responsive">
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Válvula</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    const rows = [];
                                    const type = ["", "Cilindro Nuevo", "Recarga", "Artículo"];

                                    for (let i = 0; i < listOrderProduct.length; i++) {
                                        var productId = listOrderProduct[i].productId;
                                        var product = products.find((item) => item.id === productId);

                                        if (product.productTypeId === 1) {
                                            rows.push(
                                                <tr key={listOrderProduct[i].id}>
                                                    <th scope="row" className="align-middle">
                                                        {listOrderProduct[i].quantity}
                                                    </th>
                                                    <td className="align-middle">{type[product.orderTypeId]}</td>
                                                    <td className="align-middle">{product.name}</td>
                                                    {product.orderTypeId === 2 ? <td className="align-middle">Válvula de Rosca</td> : <td className="align-middle"></td>}
                                                </tr>
                                            );
                                        } else {
                                            var productTwoId = listOrderProduct[i + 1].productId;
                                            var productTwo = products.find((item) => item.id === productTwoId);

                                            rows.push(
                                                <tr key={listOrderProduct[i].id}>
                                                    <th scope="row" className="align-middle">
                                                        {listOrderProduct[i].quantity}
                                                    </th>
                                                    <td className="align-middle">{type[product.orderTypeId]}</td>
                                                    <td className="align-middle">{product.name}</td>
                                                    <td className="align-middle">{productTwo.name}</td>
                                                </tr>
                                            );
                                            i++;
                                        }
                                    }
                                    return rows;
                                })()}
                            </tbody>
                        </table>

                        {/* <Stack direction={{ sm: "column", md: "row" }} spacing={3} justifyContent="space-between">
                  <button className="btn btn-danger w-50">Cancelar</button>
                  <button
                     className="btn btn-primary w-50"
                     onClick={() => {
                        HandleOnClick(5);
                     }}
                  >
                     Entregar
                  </button>
               </Stack> */}
                    </div>{" "}
                </>
            )}
        </Container>
    );
};
